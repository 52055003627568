import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import ColumnType from './ColumnType';

const ConditionsFields = ({ fields, data, onChange }) => {
  const [type, setType] = useState({ type: '', name: '' });
  const [selectedField, setSelectedField] = useState(
    data?.field ? fields[data.field] : fields[type.name]
  );

  useEffect(() => {
    setSelectedField(data?.field ? fields[data.field] : fields[type.name]);
  }, [data]);

  const onTypeChange = (value, option) => {
    onChange({ ...data, field: value, type: 'and' });
    setType((prevState) => ({ ...prevState, type: option.data.type, name: option.key }));
  };

  const filterOption = (input, option) =>
    (option?.props?.children ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div className='flex items-center gap-1 flex-wrap'>
      <div className='flex items-center '>
        <span className='rounded-md bg-color-xlight h-px w-5 mx-1' />
        <Select
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          className='min-w-36'
          showSearch
          placeholder='Select Field'
          optionFilterProp='children'
          onChange={onTypeChange}
          defaultValue={data.field}
          filterOption={filterOption}
        >
          {Object.keys(fields).map((key) => (
            <Select.Option
              key={key}
              value={fields[key].name}
              data={fields[key]}
              type={fields[key].type}
            >
              {fields[key].label}
            </Select.Option>
          ))}
        </Select>
      </div>
      {selectedField && (
        <>
          <div className='flex items-center'>
            <span className='rounded-md bg-color-xlight h-px w-5 mr-1' />
            <Select
              classNa
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              className='w-36'
              placeholder='Select Operator'
              defaultValue={data.operator}
              onChange={(v) => {
                onChange({ ...data, operator: v });
              }}
            >
              {selectedField.filters.map((name, key) => (
                <Select.Option key={key} value={name}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className='flex items-center'>
            <span className='rounded-md bg-color-xlight h-px w-5 mr-1' />
            <ColumnType
              type={type?.type || selectedField?.type}
              options={selectedField?.options}
              onChange={onChange}
              data={data}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default ConditionsFields;
