import React, { useState } from 'react';
import { Typography, CircularProgress, Tooltip, makeStyles } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiLink, mdiContentCopy } from '@mdi/js';

const UrlNameFormatter = ({ row }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  if (!row.url) {
    return '-';
  }

  const handleCopy = async () => {
    setIsLoading(true);
    navigator.clipboard.writeText(row.url);
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  };

  return (
    <div className='w-full'>
      {row.url && (
        <div className='flex justify-between gap-2 text-left w-full text-xs rounded-md flex items-center h-8 truncate'>
          <Typography
            variant='h6'
            className='min-w-48 flex-nowrap truncate text-ellipsis text-blue'
          >
            {row.url}
          </Typography>
          <div className='flex items-center gap-1'>
            {isLoading ? (
              <CircularProgress className={classes.spinner} />
            ) : (
              <Tooltip title='Copy Link' placement='bottom' arrow classes={classes}>
                <a
                  onClick={handleCopy}
                  className='rounded-full bg-orange flex items-center p-1.5 cursor-pointer transition-all duration-300 hover:scale-110'
                >
                  <Icon
                    path={mdiContentCopy}
                    className='!w-[14px] !h-[14px] hover:scale-110 !text-white'
                    alt='copy'
                  />
                </a>
              </Tooltip>
            )}
            <a
              rel='noreferrer'
              target='_blank'
              href={row.url}
              className='rounded-full bg-orange flex items-center p-1.5 cursor-pointer transition-all duration-300 hover:scale-110'
            >
              <Icon
                path={mdiLink}
                className='!w-[14px] !h-[14px] hover:scale-110 !text-white'
                alt='link'
              />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default UrlNameFormatter;

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: '#000',
  },
  tooltip: {
    color: '#000',
    backgroundColor: '#fff',
    fontSize: '11px',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.5)',
    '& .MuiTooltip-tooltipPlacementBottom': {
      margin: '5px !important',
    },
    '& .MuiTooltip-arrow::before': { color: '#fff', boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.5)' },
  },
  spinner: {
    width: '20px !important',
    height: '20px !important',
    '& .MuiCircularProgress-svg': {
      width: '20px !important',
      height: '20px !important',
    },
  },
}));
