import { useCallback, useMemo } from 'react';
import { useNetworkAccountsListQuery } from 'store/redux/apis/network-accounts';
import ninjaApi from '../apis';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

/**
 * Returns all network accounts data
 * including default account data
 * @typedef NetworkAccountHookData
 * @property {Array<NetworkAccount>} accounts
 * @property {Boolean} accounts.isLoading
 *
 *
 * @returns {NetworkAccountHookData & RTKQueryHook}
 */
export default function useNetworkAccounts(query = { active: true }) {
  const {
    data: allAccountsResponse = [],
    isFetching,
    isError,
    refetch,
  } = useNetworkAccountsListQuery(query);

  const allAccounts = Array.isArray(allAccountsResponse)
    ? allAccountsResponse
    : allAccountsResponse?.data || [];

  return {
    accounts: allAccounts,
    google: allAccounts.filter((acc) => acc.network === 'google'),
    facebook: allAccounts.filter((acc) => acc.network === 'facebook'),
    isLoading: isFetching,
    isError,
    refetch,
  };

  // return {
  //   accounts: accounts,
  //   google: accounts.filter((acc) => acc.network === 'google'),
  //   facebook: accounts.filter((acc) => acc.network === 'facebook'),
  //   isLoading: isFetching,
  //   isError,
  //   refetch,
  // };
}

export const useAllNetworkAccounts = (forselect = false) => {
  const { google, facebook, isLoading } = useNetworkAccounts();

  if (isLoading) {
    return [];
  }

  if (forselect) {
    return [...google, ...facebook].reduce((prev, acc) => {
      prev[acc.id.toString()] = { name: acc.name, value: acc.id.toString() };
      return prev;
    }, {});
  }

  return [...google, ...facebook];
};
