import React, { useEffect, useState } from 'react';
import ConnectedWoocommerceStores from './ConnectedWoocommerceStores';
import { Alert, Button } from 'antd';
import WoocommerceConnectDrawer from './WooCommerceConnectDrawer';
import Ninja from '@ninja';
import { useIntegrateWoocommerceStoreMutation } from 'store/redux/apis/apps/woocommerce.api';
import { LinearProgress } from '@material-ui/core';

const WoocommerceAppDrawer = (props) => {
  const [connectModalOpened, setConenctModalOpened] = useState(
    Boolean(Ninja.getUrlParam('connect', false))
  );
  const [success, setSuccess] = useState(Boolean(Ninja.getUrlParam('success', false)));
  const [user_id] = useState(Ninja.getUrlParam('user_id', false));
  const [store_id] = useState(Ninja.getUrlParam('store_id', false));
  const [integrateStoreQuery, { isLoading, isSuccess }] = useIntegrateWoocommerceStoreMutation();

  useEffect(() => {
    if (!user_id) return;

    integrateStoreQuery({
      user_id: user_id,
      store_id: store_id,
    }).unwrap();
  }, [user_id]);

  return (
    <div className='flex flex-col gap-4'>
      <div className='py-4'>
        <Button type='primary' onClick={() => setConenctModalOpened(true)}>
          Connect New Store
        </Button>
      </div>

      {isSuccess && (
        <div className='w-full'>
          <Alert type='success' message='Your store successfylly connected' />
        </div>
      )}

      {isLoading && (
        <div className='w-full flex flex-col'>
          Connecting...
          <LinearProgress />
        </div>
      )}

      <div>
        <ConnectedWoocommerceStores />
      </div>

      <WoocommerceConnectDrawer
        open={connectModalOpened}
        onClose={() => setConenctModalOpened(false)}
      />
    </div>
  );
};

export default WoocommerceAppDrawer;
