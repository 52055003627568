import { Select, Space, Divider } from 'antd';
import NetworkIcon from 'components/NetworkIcon/NetworkIcon';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { StatusFormatter } from 'components/tables/NinjaTable/formatters';
import { mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import ninjaApi from 'store/redux/apis';
import { LoadingOutlined } from '@ant-design/icons';
import NetworkSwitch from 'components/switches/NetworkSwitch';
import debounce from 'common/debounce';

const CampaignSelect = (props) => {
  const {
    value = [],
    network = 'all',
    onChange,
    multiple = true,
    id,
    disableNetworksSelector = false,
    disableSelectAll = true,
    placeholder = 'Campaign',
    label = 'Type To Search',
    preload = 20,
    ...rest
  } = props;

  const [search, setSearch] = useState();
  const [open, setOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [load, setLoad] = useState(preload);
  const [selectedNetwork, setSelectedNetwork] = useState(network);
  const [getSearchCampaignQuery, { data = [], isFetching }] = ninjaApi.useLazySearchCampaignQuery();

  const fetchCampaign = useCallback(() => {
    getSearchCampaignQuery({
      network: selectedNetwork,
      search: search,
      id: id,
      preload: load,
    }).unwrap();
  }, [selectedNetwork, search, id, load]);

  const handleChange = (value) => {
    onChange?.(value);
  };

  const handleNetworksChange = (network) => {
    setSelectedNetwork(network);
  };

  const options = useMemo(() => {
    return data.filter((acc) => selectedNetwork === 'all' || acc.network === selectedNetwork);
  }, [data, selectedNetwork]);

  useEffect(() => {
    handleSearch('');
  }, [selectedNetwork]);

  const handleSearch = debounce((searchValue) => {
    setSearch(searchValue);
  }, 200);

  const renderNotFoundContent = () => {
    return (
      <LoadingOutlined className='text-3xl m-auto flex items-center justify-center h-9' spin />
    );
  };

  useEffect(() => {
    if (scrollPosition > 90 && !(load > data.length)) {
      setTimeout(() => {
        setLoad(load + 50);
      }, 200);
    }
  }, [scrollPosition]);

  useEffect(() => {
    open && fetchCampaign();
  }, [fetchCampaign, open]);

  const handleClose = () => {
    setOpen(!open);
    setSearch('');
  };

  const onSearch = (value) => {
    handleSearch(value);
  };

  return (
    <Select
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      open={open}
      placeholder={open ? label : placeholder}
      mode='multiple'
      maxTagCount='responsive'
      onChange={handleChange}
      value={value}
      allowClear
      optionFilterProp='label'
      onDropdownVisibleChange={handleClose}
      onPopupScroll={(e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
        if (scrollPercentage > 90) {
          setScrollPosition(scrollPercentage);
        }
      }}
      notFoundContent={isFetching && options.length === 0 ? renderNotFoundContent() : undefined}
      onSearch={onSearch}
      filterOption={(input, option) => {
        const isMatch = option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        return isMatch;
      }}
      dropdownRender={(menu) => (
        <>
          <NetworkSwitch
            selectedNetwork={selectedNetwork}
            handleNetworksChange={handleNetworksChange}
            disableNetworksSelector={disableNetworksSelector}
            disableSelectAll={disableSelectAll}
          />
          <div>
            {menu}
            {isFetching && options.length ? (
              <div className='w-full m-auto text-center'>
                <LoadingOutlined className='m-auto' />
              </div>
            ) : null}
          </div>
        </>
      )}
      {...rest}
    >
      {options?.map((opt, index) => {
        return (
          <Select.Option key={index} value={opt.id} label={opt.name}>
            <Space className='flex items-center justify-between w-full'>
              <div className='flex items-center justify-center w-full gap-2'>
                {opt.status && <StatusFormatter row={opt} title={false} />}
                <span role='img' aria-label={opt.name}>
                  <NetworkIcon network={opt.network} size={15} />
                </span>
                {opt.name}
              </div>
              {opt.type === 'search' && (
                <Icon className='text-color-light' path={mdiMagnify} size='1rem' />
              )}
            </Space>
          </Select.Option>
        );
      })}
    </Select>
  );
};
export default CampaignSelect;
