import React, { Fragment, useEffect, useMemo } from 'react';
import { Alert, Button, Checkbox, Drawer, Tooltip } from 'antd';
import TextField from 'components/form/fields/TextField';
import { useFormik } from 'formik';
import Icon from '@mdi/react';
import { mdiCheck, mdiLink } from '@mdi/js';
import Currency from 'components/Currency';
import * as yup from 'yup';
import {
  useCheckWoocommerceStoreUrlMutation,
  useRequestWoocommerceAuthorizationMutation,
} from 'store/redux/apis/apps/woocommerce.api';
import { debounce } from 'lodash';

const WoocommerceConnectDrawer = (props) => {
  const { ...rest } = props;
  const [requestWoocommerceStoreAuthorizationQuery, { isLoading: isConnectLoading }] =
    useRequestWoocommerceAuthorizationMutation();
  const [
    checkStoreUrlQuery,
    {
      isLoading: isCheckLoading,
      data: checkResults = null,
      isUninitialized: isCheckUniniatialized,
    },
  ] = useCheckWoocommerceStoreUrlMutation();
  const canConnect = checkResults && checkResults.can_connect;

  const onFormSubmit = () => {
    if (!canConnect) return alert('Please fill form');
    requestWoocommerceStoreAuthorizationQuery(form.values)
      .unwrap()
      .then((data) => {
        window.open(data.redirect_url, '_blank');
      });
  };

  const form = useFormik({
    initialValues: {
      base_url: '',
      name: '',
      currency: 'USD',
      data_consent: true,
    },
    onSubmit: onFormSubmit,
    validationSchema: validationSchema,
  });

  const checkStoreUrl = useMemo(
    () =>
      debounce((url) => {
        checkStoreUrlQuery({
          url: url,
        })
          .unwrap()
          .then((res) => {
            console.log(res);
          });
      }, 500),
    []
  );

  useEffect(() => {
    if (form.values.base_url.length) {
      checkStoreUrl(form.values.base_url);
    }
  }, [form.values.base_url]);

  return (
    <Drawer destroyOnClose title='Connect Woocommerce Store' {...rest}>
      <div className='flex flex-col gap-6 mt-4'>
        <TextField
          error={form.touched.name && form.errors.name}
          helperText={form.errors.name}
          label='Store Name'
          name='name'
          value={form.values.name}
          onChange={form.handleChange}
        />
        <TextField
          label='Store Url'
          name='base_url'
          value={form.values.base_url}
          error={form.touched.base_url && form.errors.base_url}
          defaultValue={form.values.base_url}
          helperText={form.errors.base_url}
          onChange={form.handleChange}
        />
        <div className='h-[3.3rem]'>
          <Currency
            value={form.values.currency}
            onChange={(val) => form.setFieldValue('currency', val)}
          />
        </div>
        <Tooltip
          zIndex={999999999999}
          title='If not checked we will not sync and process customer personal information in our crm'
        >
          <Checkbox
            onChange={(e) => form.setFieldValue('data_consent', e.target.checked)}
            checked={form.values.data_consent}
          >
            Process customer personal information
          </Checkbox>
        </Tooltip>
        <div className='flex justify-center gap-2'>
          <Button
            className='flex items-center justify-center'
            onClick={form.handleSubmit}
            loading={isCheckLoading || isConnectLoading}
            type='primary'
            icon={<Icon path={mdiLink} />}
            disabled={!canConnect}
          >
            {isCheckLoading ? 'Checking Your Website' : 'Connect'}
          </Button>
          {!isCheckUniniatialized && !canConnect ? (
            <Button
              className='flex items-center justify-center'
              onClick={checkStoreUrl}
              loading={isCheckLoading || isConnectLoading}
              type='primary'
              icon={<Icon path={mdiCheck} />}
            >
              Check Again
            </Button>
          ) : null}
        </div>
        <div className='w-full flex-col flex gap-2'>
          {checkResults && !checkResults.woocommerce ? (
            <Alert
              type='error'
              closable={true}
              message='WooCommerce is not installed'
              description={
                <Fragment>
                  WooCommerce plugin is not installed or active in your store.
                  <a target='_blank' href={checkResults.woocommerce_install_url}>
                    Click here
                  </a>
                  to install it
                </Fragment>
              }
            />
          ) : null}
          {checkResults && !checkResults.mydataninja ? (
            <Alert
              type='error'
              closable={true}
              message='MyDataNinja is not installed'
              description={
                <Fragment>
                  MyDataNinja plugin is not installed or active in your store.
                  <a target='_blank' href={checkResults.mydataninja_install_url}>
                    Click here
                  </a>
                  to download and install it
                </Fragment>
              }
            />
          ) : null}
        </div>
      </div>
    </Drawer>
  );
};

const validationSchema = yup.object({
  base_url: yup.string().url('Please enter a valid URL').required('Please enter a valid URL'),
  name: yup.string('Enter name').required('Enter name').min(2).max(50),
});

export default WoocommerceConnectDrawer;
