import { useWizard } from 'modules/WizardModule/wizardUtils';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import P from 'router/paths';
import { usePermissions } from 'store/redux/apis/workspace';
import useAuth from 'store/redux/hooks/useAuth';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import { useLocation } from 'react-router-dom';

const useMiddleware = () => {
  const { canView, canEdit } = usePermissions();
  const { loading: authLoading, auth } = useAuth();
  const { workspace } = useWorkspace();
  const history = useHistory();
  const { wizard: current_step, getStepIndex } = useWizard();
  const location = useLocation();

  /**
   * Redirect to page with chance to return back
   * @todo add from param, for later to come back
   */
  const middlewareRedirect = (path, state = {}) => {
    // const url = path;
    const fullLocation = { pathname: path, search: location.search, state };
    history.push(fullLocation);
  };

  /**
   * Defined middlewares can be:
   * auth - checks if authenticated
   * editor - checks if user has edit role on given module
   * viewer - checks if user has view role on given module
   * guest @todo - needs to create guest middleware
   */
  const mids = useMemo(
    () => ({
      auth: () => ({
        pass: auth,
        onError: () => {
          console.log('Auth middleware fails, Redirecting to login ... ');
          middlewareRedirect(P.AUTH.LOGIN, {
            redirect_to: location.pathname,
          });
        },
      }),
      guest: () => ({
        pass: !auth,
        onError: () => {
          console.log('Guest middleware fails, Redirecting to Dashboard ... ');
          const redirectTo = location.state?.redirect_to || P.DASHBOARD.INDEX;
          middlewareRedirect(redirectTo);
        },
      }),
      editor: (mod) => ({
        pass: canEdit(mod),
        onError: () => {
          console.log('Editor middleware fails, Redirecting to permission error ... ');
          middlewareRedirect(P.DASHBOARD.PERMISSION_ERROR);
        },
      }),
      viewer: (mod) => ({
        pass: canView(mod),
        onError: () => {
          console.log('Viewer middleware fails, Redirecting to permission error ... ');
          middlewareRedirect(P.DASHBOARD.PERMISSION_ERROR);
        },
      }),
      wizard: (step) => {
        // set pass initially to false
        let pass = false;

        // If everything is completed, pass is ready to fail
        if (!current_step || current_step === 'completed' || current_step === step) {
          pass = true;
        } else {
          // Get current step and given step index
          const currentStepIndex = getStepIndex(current_step);
          const givenStepindex = getStepIndex(step);

          // if current step index greater than given, that means that
          // given step is passed, so pass will be true
          if (currentStepIndex > givenStepindex) {
            pass = true;
          }
        }

        return {
          pass: pass,
          onError: () => {
            console.log(
              `Wizard middleware fails: current step: [${current_step}], required at least: [${step}] ... `
            );
            middlewareRedirect(P.WIZARD.INDEX);
          },
        };
      },
    }),
    [auth, current_step, workspace?.id]
  );

  /**
   * Check single middleware
   * @param {String} mid
   * @returns {Object|null}
   */
  const check = (mid) => {
    // Parse middleware arguments
    const middleware = parseMiddleware(mid);
    // If given middleware not found, do nothing
    if (typeof mids[middleware.middleware] === 'undefined') return null;

    // Parse middleware result
    const result = mids[middleware.middleware](middleware.guard);

    // Console message
    console.log(
      `middleware checked: ${middleware.middleware} ${
        middleware.guard ? `with guard ${middleware.guard}` : ''
      }`
    );
    // If result is null or result is pass, do nothing
    if (result === null || result.pass) return null;

    // Else if middleware fails, and exists onError action,
    // return that and route knows what to do with it
    if (!result.pass && result.onError) {
      return result;
    }

    return null;
  };

  /**
   * Checks all middleware, if error returns error
   * @param {Array} mids
   * @returns {Object|null}
   */
  const checkAll = (mids) => {
    if (!Array.isArray(mids)) return null;

    for (let i = 0, l = mids.length; i < l; i++) {

      const result = check(mids[i]);
      if (result === null) continue;

      return result;
    }

    return null;
  };

  return {
    check,
    checkAll,
  };
};

const parseMiddleware = (mid) => {
  const [mid_name, guard] = mid.split(':');

  return {
    middleware: mid_name,
    guard: guard,
  };
};

export default useMiddleware;
