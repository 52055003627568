import React, { useEffect } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { Button, Typography, makeStyles } from '@material-ui/core';
import { useNinjaTable } from './NinjaTable';
import { useListFiltersQuery } from 'store/redux/apis/workspace';
import ninjaApi from 'store/redux/apis';
import { mdiFilter } from '@mdi/js';
import Icon from '@mdi/react';

const SavedFilters = () => {
  const classes = useStyles();
  const { filters, setFilters, setSavedFilters, activeFilters, savedFiltersParams } =
    useNinjaTable();
  const [getGetSavedFilterQuery] = ninjaApi.useLazyGetSavedFilterQuery();
  const { data: savedFilters = [] } = useListFiltersQuery(savedFiltersParams);

  const toggleFilter = async (id) => {
    let newFilters = {};
    if (id !== filters?._filterId) {
      const res = await getGetSavedFilterQuery({ filter_id: id }).unwrap();
      newFilters = { ...res.filters, _filterId: res.id };
    }
    setFilters(newFilters);
  };

  useEffect(() => {
    if (_.isEqual(filters, activeFilters)) return;
    setFilters(activeFilters || {});
  }, [activeFilters]);

  useEffect(() => {
    setSavedFilters(savedFilters);
  }, [savedFilters]);

  if (!savedFilters.data?.length) return null;

  return (
    <div className={clsx('flex gap-2 items-center flex-wrap', classes.container)}>
      <Icon path={mdiFilter} className='!text-color-medium' />
      <Typography className='text-xs ml-2 font-normal text-gray'>Saved Filters:</Typography>
      <div className='flex gap-2 flex-wrap'>
        {Array.isArray(savedFilters?.data)
          ? savedFilters?.data?.map((f) => (
              <Button
                key={f.id}
                color={filters?._filterId === f.id ? 'secondary' : 'default'}
                onClick={() => {
                  toggleFilter(f.id);
                }}
                variant='outlined'
                className={filters?._filterId === f.id ? 'text-orange' : 'text-gray'}
              >
                <span
                  className={
                    filters?._filterId === f.id
                      ? 'text-orange text-xs font-normal font-medium'
                      : 'text-gray text-xs text-xs font-normal'
                  }
                >
                  {f.name}
                </span>
              </Button>
            ))
          : null}
      </div>
    </div>
  );
};

export default SavedFilters;

const useStyles = makeStyles(() => ({
  container: {
    '& .MuiButton-outlined': {
      height: 0,
      padding: '9px 10px',
    },
    '& .MuiButton-root': {
      height: '30px',
      padding: '9px 10px',
    },
  },
}));
