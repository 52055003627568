import { Select, Space } from 'antd';
import React from 'react';

const NetworkSelect = (props) => {
  const { placeholder = 'Select Network', data, mode, showColor = true } = props;

  return (
    <Select
      mode={mode}
      style={{ width: '100%' }}
      placeholder={placeholder}
      maxTagCount='responsive'
      allowClear
      {...props}
    >
      {data?.map((opt, index) => {
        return (
          <Select.Option key={index} value={opt.value} label={opt.name}>
            <Space>{opt.name}</Space>
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default NetworkSelect;
