import Module from 'modules/Module';
import React from 'react';
import P from 'router/paths';
import BillingIndexPage from './pages/BillingIndexPage';
import RedeemAppsumoCodePage from './pages/RedeemAppsumoCodePage';

const BillingModule = (props) => {
  const module = new Module({
    routes: [
      { path: P.BILLING.CHOOSE_PLAN, exact: true, component: BillingIndexPage },
      { path: P.BILLING.APPSUMO, exact: true, component: RedeemAppsumoCodePage },
    ],
  });

  return module.render();
};

export default BillingModule;
