import { Tour } from 'antd';
import './style.css';
import React, { useEffect, useState } from 'react';
import { useWizard } from 'modules/WizardModule/wizardUtils';
import { useHistory } from 'react-router-dom';
import P from 'router/paths';
import { useTour, useSidebar, useTutorial } from 'store/redux/states/ui/ui.hooks';
import UrlHelper from 'common/utils/url-helper';
import { useModal } from 'store/redux/states/ui/ui.hooks';

const Tutorial = () => {
  const history = useHistory();
  const [tutorial, setTutorialOpen] = useTutorial();
  const [sidebarOpen, setSidebarOpen] = useSidebar();
  const [updateStep, setUpdateStep] = useState();
  const [step, setStep] = useState(0);
  const [tourId, setTourId] = useTour(step);
  const [open, setOpen] = useState(false);
  const { wizard } = useWizard();
  const [choosePlanModalOpened, setChoosePlanModalOpened] = useModal('choose_plan');

  const handleFinish = (step) => {
    setOpen(false);
    setTutorialOpen(false);
  };

  const steps = [
    {
      id: 'revenue',
      title: 'How to calculate Revenue Data',
      description: (
        <div className='text-sm font-normal grid gap-2'>
          <span>
            By Default: We will fetch Revenue and Costs Data from Advertising Networks: Google Ads
            and Meta Ads.
          </span>
          <span>
            So, if you dont have your current system sending Revenue Data to Networks, our reports
            will show only Costs as well.
          </span>
          <span>
            Our Recommendation: To fully leverage MyDataNinja 100%, Use MyDataNinja CRM Revenue
            Data, but In that case, you need to connect your Data Warehouse to our system or use our
            CRM Module.
          </span>
          <span>
            To fully Understand, how it works - Read Our Blog Article
            <a
              href={UrlHelper.home('/set-up-mydataninja')}
              target='_blank'
              rel='noreferrer'
              className='text-orange ml-1'
            >
              "Set Up Instructions"
            </a>
            .
          </span>
        </div>
      ),
      target: () => document.getElementById('revenue'),
    },
    {
      id: 'reporting',
      title: 'Reporting',
      description: (
        <div className='text-sm font-normal grid gap-2'>
          <span>
            All in one reports for all of your Google Ads and Meta Ads accounts. Everything in one
            view, no more account switching or manual work.
          </span>
          <span>
            Manage advertisements, see their Real-Time revenue and profitability reports, Edit their
            budgets and understand your crucial statistics.
          </span>
        </div>
      ),
      target: () => document.getElementById('reporting'),
    },
    {
      id: 'tabs',
      title: 'Tabs',
      placement: 'top',
      description:
        'Switching Tabs helps to see data on different levels. By Default MyDataNinja works on Ad Group level. But you can switch to Campaigns tab or Ad Tab to see data grouped by them.',
      target: () => document.getElementById('tabs'),
    },
    {
      id: 'switching',
      title: 'Switching',
      description:
        'You Can Start or Pause Items by Switching On or OFF. Select Multiple items and useswitcher to turn off or on items even on Multiple accounts.',
      target: () => document.getElementById('switch'),
    },
    {
      id: 'info',
      title: 'Tree View ',
      description:
        'Once you click on Info icon, you can see Tree view of Advertisement and -10/+10 days quick performance data',
      target: () => document.getElementById('info'),
    },
    {
      id: 'inner',
      title: 'See More Detail',
      description:
        'Click to see more detailed report of the Ad Group segmented by different devices, demographics and etc.',
      target: () => document.getElementById('inner'),
    },
    {
      id: 'duplicate',
      title: 'Duplicate',
      description:
        'Duplicate Ad Group, Campaign or AD easily within a seconds. (Only works on Meta for now)',
      target: () => document.getElementById('duplicate'),
    },
    {
      id: 'budget',
      title: 'Budget Editing',
      description: 'Edit Ad Set or Campaign Budget quickly, without visiting Ad Networks',
      target: () => document.getElementById('budget'),
    },
    {
      id: 'filter',
      title: 'Easily Filter Advertisements, Campaigns or Ads by any parameters or results',
      description:
        'For Example, if you want to see last 7 days Ad Groups with unprofitable results, make it happen within a seconds. You can Save This Filter for quickly access it in the future.',
      target: () => document.getElementById('filter'),
    },
  ];
  useEffect(() => {
    if (tutorial) {
      if (choosePlanModalOpened === true) {
        setOpen(false);
      } else {
        setOpen(true);
      }
      if (wizard === 'completed') {
        if (tutorial === null) {
          setOpen(true);
        }
      }
    }
  }, [wizard, choosePlanModalOpened, tutorial]);

  useEffect(() => {
    setTimeout(() => {
      setUpdateStep(step);
    }, 400);
  });

  const handleChangeStep = (step_id) => {
    const currentStep = steps[step_id];
    setStep(step_id);
    setTourId(currentStep.id);
    if (currentStep.id === 'reporting') {
      history.push(P.REPORTING.ADSET);
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  };

  return (
    <Tour
      open={open}
      steps={steps}
      onChange={handleChangeStep}
      onClose={handleFinish}
      current={updateStep}
      mask={{
        color: 'rgba(0, 0, 0, .7)',
      }}
    />
  );
};
export default Tutorial;
