import React from 'react';
import { useSharedData } from 'store/redux/states/ui/ui.hooks';
import { apps } from 'modules/AppsModule/apps.config';
import { Drawer } from 'antd';

const AppsDrawer = () => {
  const [chosenApp, setChosenApp] = useSharedData('chosen_app');

  if (!chosenApp) return null;

  if (!apps[chosenApp] || typeof apps[chosenApp] === 'undefined') {
    return null;
  }

  const app = apps[chosenApp];
  const Component = app.component;

  const onClose = (open) => {
    setChosenApp(null);
  };

  return (
    <Drawer title={app.name} placement='right' size='large' onClose={onClose} open={true}>
      <Component />
    </Drawer>
  );
};

export default AppsDrawer;
