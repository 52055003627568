import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { drawerWidth, shrinkDrawerWidth } from '@ninja/consts';
import React from 'react';
import { Link } from 'react-router-dom';
import P from 'router/paths';
import Logo from './Logo';
import PersonalAssistant from './PersonalAssistant';
import SidebarMenu from './SidebarMenu';
import SidebarToggler from './SidebarToggler';
import { useSharedData, useSidebar } from 'store/redux/states/ui/ui.hooks';

const Sidebar = (props) => {
  const classes = useStyles();
  const [open, , toggleSidebar] = useSidebar();
  const [assistentExpanded, setAssistedExpanded] = useSharedData('assistent_expanded');

  return (
    <Drawer
      anchor='left'
      onClose={toggleSidebar}
      variant='permanent'
      open={open}
      classes={{
        paper: clsx(classes.drawer, 'light-scroll', {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      {...props}
    >
      <Link to={P.DASHBOARD.INDEX} className='no-underline'>
        <Logo />
      </Link>
      <SidebarMenu />
      <div className={classes.sidebarBottom}>
        <div
          onClick={() => (!assistentExpanded ? setAssistedExpanded(true) : null)}
          className={classes.personalAssistantContainer}
        >
          {!open && (
            <div className='relative'>
              <img src='/images/gurami.jpg' className='rounded-full w-10 absolute ' alt='' />
            </div>
          )}
          <PersonalAssistant />
        </div>
        <SidebarToggler />
      </div>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    background: '#151a22',
    transition: '0.4s width ease-in',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      transition: '0.5s width ease-in',
    },
    position: 'static',
  },
  drawerOpen: {
    width: drawerWidth,
  },
  drawerClose: {
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: shrinkDrawerWidth,
    },
  },
  personalAssistantContainer: {
    padding: theme.spacing(2),
  },
  sidebarBottom: {
    width: '100%',
  },
}));

export default Sidebar;
