import NinjaTable from 'components/tables/NinjaTable';
import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import P from 'router/paths';

const columns = [
  {
    key: 'id',
    frozen: true,
    name: 'ID',
    resizable: true,
  },
  {
    key: 'name',
    frozen: true,
    name: 'Name',
    resizable: true,
  },
  {
    key: 'currency.name',
    frozen: true,
    name: 'Currency',
    resizable: true,
  },
  {
    key: 'url',
    frozen: true,
    name: 'Url',
    resizable: true,
  },
  {
    key: 'form_url',
    frozen: true,
    name: 'Form',
    renderCell: ({ row }) => {
      if (!row.form?.id) return '-';
      return <Link to={P.CRM.DATA_LIST.replace(':id', row.form.id)}>View</Link>;
    },
  },
];

const ConnectedWoocommerceStores = (props) => {
  return (
    <NinjaTable
      title='Connected woocommerce stores'
      columns={columns}
      dataProvider='listWoocommerce'
      footerProps={{ showPerPage: false }}
    />
  );
};

export default ConnectedWoocommerceStores;
