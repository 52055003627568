import React from 'react';
import { currency_options } from '@ninja/consts';
import { Select } from 'antd';
import { makeStyles } from '@material-ui/core';

const Currency = (props) => {
  const classes = useStyles();
  const { value, onChange, options } = props;

  return (
    <div className={classes.root}>
      <Select
        className='w-full !font-semibold h-full'
        value={value}
        onChange={onChange}
        label='Currency'
      >
        {Object.values(options || currency_options).map((currency) => (
          <Select.Option key={currency.value} value={currency.value} className='!font-semibold'>
            {currency.symbol} {currency.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
export default Currency;

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    '& .ant-select-selector': {
      height: '100% !important',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: ' 1px solid #d9d9d9 !important',
      boxShadow: 'none !important',
    },
    '& .ant-select-selector:hover': {
      border: '2px solid #4A89E3 !important',
      boxShadow: 'none !important',
    },
    '& .ant-select-selection-item': {
      fontWeight: '900 !important',
      fontSize: '12px !important',
    },
  },
}));
