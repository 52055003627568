import React from 'react';
import { Card, CardContent, CardHeader, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { colors } from 'common/@mui/theme';
import DateRangePicker from 'components/form/fields/DateRangePicker';
import NinjaTableCustomize from 'components/tables/NinjaTable/NinjaTableCustomize';
import SavedFilters from 'components/tables/NinjaTable/SavedFilters';

/** @todo move to folder */
const StyledCard = (props) => {
  const classes = useStyles({
    avatarColor: props.avatarColor || 'orange',
    flat: props.flat,
    square: props.square,
    background: props.background,
    padding: props.padding || [1.4, 3.75],
    titlePadding: props.titlePadding || [1.4, 1.4, 1.4, 3.75],
    titleColor: colors[props.titleColor],
    divider: props.divider,
  });

  const {
    withoutHeader,
    header,
    headerProps,
    cardContentProps = {},
    cardContentClassName,
    disableBottomPadding,
    datePickerProps = {},
    datepicker,
    showExpandDays,
    customizationId,
    customize,
    onCustomizationUpdate,
    exportData = false,
    customizationEdit,
    removeColumns,
    copyUrl,
    savedfilterList = false,
    ...rest
  } = props;

  return (
    <Card elevation={0} className={clsx(classes.card, classes[`border-${props.border}`])} {...rest}>
      {!withoutHeader && (
        <CardHeader
          titleTypographyProps={{
            variant: 'h4',
          }}
          subheaderTypographyProps={{
            variant: 'body1',
          }}
          classes={{
            root: classes.cardHeaderRoot,
            avatar: classes.avatar,
            action: classes.action,
          }}
          title={props.title}
          subheader={props.subTitle}
          avatar={props.avatar}
          action={
            <div className='flex flex-wrap gap-2 space-x-2 items-start mt-2 md:mt-0 items-center'>
              {savedfilterList && <SavedFilters />}
              <span className='h-9'>{header}</span>
              {datepicker && <DateRangePicker {...datePickerProps} formatter='MMM-dd' />}
              {customize && (
                <NinjaTableCustomize
                  disableLimit
                  disableLevelFilter
                  customizationId={customizationId}
                  onCustomizationUpdate={onCustomizationUpdate}
                  customizationEdit={customizationEdit}
                  removeColumns={removeColumns}
                  exportData={exportData}
                  copyUrl={copyUrl}
                />
              )}
            </div>
          }
          {...headerProps}
        />
      )}

      <CardContent
        className={clsx(classes.cardContent, cardContentClassName)}
        {...cardContentProps}
      >
        {props.children}
      </CardContent>
      {disableBottomPadding && <div />}
    </Card>
  );
};

/**
 * @todo remove
 */
const backgroundPresets = {
  'blue-gradient': 'linear-gradient(123.14deg, #2475E9 4.05%, #66A5FF 91.29%)',
};

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: (props) => (props.square ? 0 : 6),
    boxShadow: (props) =>
      props.flat ? void 0 : '0 1px 3px 0 rgb(0 0 0 / 10%)' /** @todo export to theme */,
    background: (props) => backgroundPresets[props.background] ?? theme.colors.white,
    '& .ant-drawer-mask': {
      background: 'transparent',
    },
    '& .ant-drawer-content-wrapper': {
      width: '300px !important',
      boxShadow: '0px 0px 25px 0px #b8b8b8ab !important',
    },
    '& .ant-drawer-header': {
      padding: '10px',
    },
    '& .ant-drawer-title': {
      fontSize: '14px',
    },
    '& .ant-drawer-body': {
      padding: '10px',
    },
  },
  cardHeaderRoot: {
    cursor: 'pointer',
    padding: (props) =>
      props.titlePadding ? theme.spacing(...props.titlePadding) : theme.spacing(2),
    minHeight: 60,
    borderBottom: (props) => (props.divider ? props.divider : '1px solid #EBEBEB'),
    color: (props) => props.titleColor,
    flexWrap: 'wrap',
  },
  cardContent: {
    padding: (props) => theme.spacing(...props.padding),
  },
  avatar: {
    color: (props) => theme.colors[props.avatarColor],
    display: 'flex',
  },
  action: {
    display: 'flex',
    flex: 'unset',
    alignItems: 'center',
    alignSelf: 'center',
    margin: 0,
  },
  'border-dashed': {
    border: `1px dashed ${theme.colors.xlight}`,
  },
}));

export default StyledCard;
