import React from 'react';
import { Input, Radio } from 'antd';
import Select from 'components/Selects/Select';
import DatePicker from 'components/form/fields/DateRangePicker/DatePicker';
import { makeStyles } from '@material-ui/core/styles';

const ColumnType = ({ type, options, data, onChange }) => {
  const classes = useStyles();
  let cmp;

  switch (type) {
    case 'integer':
      cmp = (
        <div className={classes.input}>
          <Input
            className='h-full'
            defaultValue={data.value}
            style={{ minHeight: 30 }}
            onChange={(e) => onChange({ ...data, value: e.target.value })}
          />
        </div>
      );
      break;
    case 'select':
      cmp = (
        <Select
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          className='w-full h-full'
          defaultValue={data.value}
          data={Object.entries(type.options).map(([k, v]) => ({
            value: k,
            name: v,
          }))}
          mode='tag'
          onChange={(v) => {
            onChange({ ...data, value: v });
          }}
        />
      );
      break;
    case 'bool':
      cmp = (
        <Radio.Group
          defaultValue={data.value}
          onChange={(e) => onChange({ ...data, value: e.target.value })}
        >
          {options.map((op, idx) => (
            <Radio.Button key={idx} value={op} className='!font-normal'>
              {op.toString()}
            </Radio.Button>
          ))}
        </Radio.Group>
      );
      break;
    case 'date':
      cmp = (
        <div className={classes.date}>
          <DatePicker
            defaultValue={data.value}
            name='date_to'
            placeholder='Date To'
            onChange={(date) => onChange({ ...data, value: date })}
          />
        </div>
      );
      break;
    default:
      cmp = <div>Unknown field type</div>;
  }
  return cmp;
};

export default ColumnType;

export const useStyles = makeStyles(() => ({
  date: {
    height: '35px',
    width: '100%',
    '& .MuiOutlinedInput-root': {
      background: 'white',
      minHeight: '35px',
      fontSize: '12x',
    },
    '& .MuiFormControl-root': {
      height: '35px',
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '12px',
    },
  },
  input: {
    height: '100%',
    width: '100%',
    '& .ant-input': {
      fontSize: '14px !important',
    },
  },
}));
