import React, { Fragment, useEffect, useMemo } from 'react';
import { Alert, Button, Drawer, Input } from 'antd';
import TextField from 'components/form/fields/TextField';
import { useFormik } from 'formik';
import Icon from '@mdi/react';
import { mdiLink } from '@mdi/js';
import * as yup from 'yup';
import { useRequestShopifyAuthorizationMutation } from 'store/redux/apis/apps/shopify.api';

const ShopifyConnectDrawer = (props) => {
  const { ...rest } = props;
  const [requestAuthorizationQuery, { isLoading: isConnectLoading }] =
    useRequestShopifyAuthorizationMutation();

  const onFormSubmit = () => {
    requestAuthorizationQuery(form.values)
      .unwrap()
      .then((data) => {
        window.open(data.url, '_blank');
      });
  };
  const storeName = 'quickstart-54710ecb';

  const form = useFormik({
    initialValues: {
      default_store_name: storeName,
      store_domain: storeName + '.myshopify.com',
      form_name: 'Test Store Form 331',
      store_name: 'Test Store 331',
    },
    onSubmit: onFormSubmit,
    // validationSchema: validationSchema,
  });

  return (
    <Drawer destroyOnClose title='Connect Shopify Store' {...rest}>
      <div className='flex flex-col gap-6 mt-4'>
        <TextField
          error={form.touched.store_name && form.errors.store_name}
          helperText={form.errors.store_name}
          label='Store Name'
          name='store_name'
          value={form.values.store_name}
          onChange={form.handleChange}
        />
        <TextField
          error={form.touched.form_name && form.errors.form_name}
          helperText={form.errors.form_name}
          label='Form Name'
          name='form_name'
          value={form.values.form_name}
          onChange={form.handleChange}
        />
        <div className='flex gap-2'>
          <TextField
            label='Store Url'
            name='default_store_name'
            value={form.values.default_store_name}
            error={form.touched.default_store_name && form.errors.default_store_name}
            defaultValue={form.values.default_store_name}
            helperText={form.errors.default_store_name}
            onChange={(e) => {
              form.setFieldValue('default_store_name', e.target.value);
              form.setFieldValue('store_domain', e.target.value + '.myshopify.com');
            }}
            className='w-full'
          />
          <div className='w-56'>
            <TextField disabled={true} value='.myshopify.com' />
          </div>
        </div>

        <div className='flex justify-center'>
          <Button
            className='flex items-center justify-center'
            loading={isConnectLoading}
            type='primary'
            onClick={form.handleSubmit}
            icon={<Icon path={mdiLink} />}
          >
            Connect
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

const validationSchema = yup.object({
  form: yup.string().url('Please enter a valid URL').required('Please enter a valid URL'),
  name: yup.string('Enter name').required('Enter name').min(2).max(50),
});

export default ShopifyConnectDrawer;
