import { Card } from 'antd';
import React from 'react';
import { useSharedData } from 'store/redux/states/ui/ui.hooks';

const AppCard = ({ app }) => {
  const [, setChosenApp] = useSharedData('chosen_app');

  return (
    <Card
      size='small'
      onClick={() => setChosenApp(app.app)}
      hoverable
      cover={
        <img src={app.image} className='w-1/2 py-2 h-[150px] px-[20%] m-auto object-contain' />
      }
      bordered={true}
    >
      <Card.Meta title={app.name} description={app.description} />
    </Card>
  );
};

export default AppCard;
