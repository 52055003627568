import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Form } from 'antd';

const FormItem = ({ children, label, message, required = false, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.form}>
      <Form.Item
        label={label}
        name={label}
        rules={[
          {
            required: required,
            message: { message },
          },
        ]}
        {...rest}
      >
        {children}
      </Form.Item>
    </div>
  );
};

export default FormItem;

const useStyles = makeStyles(() => ({
  form: {
    '& .ant-form-item-required': {
      fontSize: '12px !important',
      fontWeight: '600',
    },
    '& .ant-form-item-label': {
      margin: 0,
      padding: 0,
    },
    '& .ant-form-item::before': {
      display: 'none',
    },
    '& .ant-form-item .ant-form-item-label >label.ant-form-item-required::before': {
      display: 'none',
    },
    '& .ant-form-item': {
      marginBottom: '15px !important',
    },
    '& .ant-form-item-explain-error': {
      fontWeight: 400,
    },
  },
}));
