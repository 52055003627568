import PlanCard from 'components/PlanCard';
import { Page } from 'components/layouts/components';
import React from 'react';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import useBilling from 'store/redux/states/billing/hooks/useBilling';
import ClaimAppsumoCodeForm from '../components/ClaimAppsumoCodeForm';
import AppsumoClaimedCodesList from '../components/AppsumoClaimedCodesList';
import {
  useAppsumoBillingPlansQuery,
  useGetClaimedCodesQuery,
} from 'store/redux/apis/apps/appsumo.api';
import { Typography } from 'antd';

const RedeemAppsumoCodePage = (props) => {
  const { data } = useAppsumoBillingPlansQuery();
  const { data: claimed_codes } = useGetClaimedCodesQuery();
  const { billing, isLoading: isBillingDataLoading } = useBilling();

  const plans = Array.isArray(data?.plans)
    ? [...data.plans].sort((a, b) => a.required_code_count - b.required_code_count)
    : [];

  return (
    <Page>
      <div className='flex-col'>
        <div className='flex flex-col justify-center items-center gap-2 w-full py-2 '>
          <span className='text-lg text-center font-medium'>
            <div className='flex'>
              <ClaimAppsumoCodeForm />
            </div>
          </span>
        </div>

        <div className='flex flex-center flex-wrap gap-4 py-2'>
          Claimed Codes: <AppsumoClaimedCodesList />
        </div>
        <div className='flex flex-center flex-wrap gap-4 mt-8'>
          {plans.map((appsumo_config) => {
            if (appsumo_config.required_code_count < claimed_codes?.codes?.length) return null;

            return (
              <div className='flex flex-col w-full sm:w-1/2 md:w-1/3 lg:w-1/4 text-center'>
                <Typography.Title level={4}>
                  {appsumo_config.plan.code === billing?.plan?.code
                    ? 'Current Plan'
                    : `${appsumo_config.required_code_count} Codes Required`}
                </Typography.Title>
                <PlanCard
                  isCurrent={false}
                  plan={appsumo_config.plan}
                  free_trial_active={false}
                  disable_button={true}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Page>
  );
};

export default RedeemAppsumoCodePage;
