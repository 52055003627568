import React from 'react';
import { makeStyles } from '@material-ui/core';
import { mdiCheckCircle } from '@mdi/js';
import Icon from '@mdi/react';
import getFormattedValue from 'store/redux/utils/getFormattedValue';

const LineProgressFormatter = ({ min, max, average, currency, symbol }) => {
  const classes = useStyles();
  const done = max === average;

  const calculatePercentage = () => {
    if (max === average) {
      return 100; // When max and min are equal, set percentage to 100%
    }
    return ((average - min) / (max - min)) * 100;
  };

  const percentage = calculatePercentage();

  const formatValue = (value) => {
    return currency ? getFormattedValue(value, currency) : `${value}${symbol}`;
  };

  return (
    <div className={classes.progressBarContainer}>
      {!done && (
        <div className='flex items-center justify-between gap-2 mt-2 h-5'>
          <span className='text-red !text-[11px] font-semibold'>{formatValue(min)}</span>
          <span className='text-green !text-[11px] font-semibold'>{formatValue(max)}</span>
        </div>
      )}
      <span
        className={classes.averageLabel}
        style={{
          left: `${percentage}%`,
          transform: done ? 'translateX(-100%)' : 'translateX(-50%)',
        }}
      >
        {formatValue(average)}
      </span>
      <span className={classes.averageDot} style={{ left: `${percentage}%` }}>
        {done ? (
          <Icon path={mdiCheckCircle} className={classes.done} color='rgb(145, 199, 22)' />
        ) : (
          <div className={classes.dot}></div>
        )}
      </span>
    </div>
  );
};

export default LineProgressFormatter;

const useStyles = makeStyles(() => ({
  progressBarContainer: {
    width: '100%',
    position: 'relative',
    height: '5px',
    backgroundColor: '#cfdfeb',
    background: 'linear-gradient(to right,  #EB2F20, #FF4E00, #91c71696, #91C716)',
    borderRadius: '10px',
  },
  averageLabel: {
    position: 'absolute',
    fontSize: '12px',
    color: '#2c435a',
    fontWeight: 600,
    lineHeight: '0px',
    bottom: '15px',
  },
  averageDot: {
    position: 'absolute',
    fontSize: '12px',
    color: '#2c435a',
    fontWeight: 600,
    lineHeight: '0px',
    top: '-0.1rem',
  },
  dot: {
    position: 'absolute',
    width: '8px',
    height: '8px',
    background: '#2c435a',
    boxShadow: '0px 0px 7px 2px white',
    borderRadius: '100%',
  },
  done: {
    position: 'absolute',
    width: '12px !important',
    height: '12px !important',
    background: 'white',
    boxShadow: '0px 0px 7px 1px white',
    borderRadius: '100%',
    top: '-2px',
    right: '0px',
  },
}));
