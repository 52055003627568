import clsx from 'clsx';
import React from 'react';
import { makeStyles } from '@material-ui/core';

const NetworkFormatter = ({ row, column }) => {
  const classes = useStyles();

  return (
    <div className='flex text-center'>
      {row.network.toLowerCase() === 'facebook' && (
        <img src='/icons/facebook.svg' alt='fb' className={classes.socialIcon} />
      )}
      {row.network.toLowerCase() === 'google' && (
        <img src='/icons/google.svg' alt='go' className={classes.socialIcon} />
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  socialIcon: {
    marginLeft: '10px',
    minWidth: 18,
    margin: 'auto',
  },
}));

export default NetworkFormatter;
