import EventEmitter from '@ninja/core/EventEmitter';

const DATA_KEY = 'mydataninja';

const EVENTS = {
  CHANGE: 'change',
  REMOVE: 'remove',
  READ: 'read',
  STORAGE_CLEAR: 'storage:clear',
  STORAGE_INIT: 'storage:init',
};

class Storage {
  constructor() {
    this.events = new EventEmitter();
    this.init();
  }

  /**
   * Initialize storage
   */
  init() {
    this.loadInitialData();
    this.fireChangeEventForAll();
    this.addWindowEventListener();
  }

  /**
   * Load initial data from localStorage
   * @returns {void}
   */
  loadInitialData() {
    try {
      // try to parse data from localStorage
      const data = JSON.parse(localStorage.getItem(DATA_KEY)) || {};

      // if data is object, set it to data
      if (data && typeof data === 'object' && data.constructor === Object) {
        this.data = data;
      } else {
        throw new Error('Data is not an object');
      }
    } catch (e) {
      this.data = {};
    }
  }

  /**
   * Fire change event for all keys for initial rendering
   * @returns {void}
   */
  fireChangeEventForAll() {
    for (const key in this.data) {
      this.fire(this.getEventName(EVENTS.CHANGE, key), this.data[key], EVENTS.CHANGE, key);
    }
  }

  /**
   * Add global window event listener, in case something changes in new window
   */
  addWindowEventListener() {
    window.addEventListener('storage', (event) => {
      if (event.key !== DATA_KEY) return;
      const key = event.key;

      this.fire(this.getEventName(EVENTS.CHANGE, key), this.data[key], EVENTS.CHANGE, key);
    });
  }

  /**
   * Get event name by event name and key
   * @param {String} event
   * @param {String} key
   * @returns {String}
   * @example
   * getEventName('change', 'key') // storage:change:key
   * getEventName('remove', 'key') // storage:remove:key
   */
  getEventName(event, key) {
    if (!event || !key) {
      throw new Error('Event and key are required');
    }
    return `storage:${event}:${key}`;
  }

  /**
   * Set a key-value pair in the storage
   * @param {String} key - The key to set
   * @param {any} value - The value to set
   * @returns {Storage} - The Storage instance
   * @example
   * storage.set('name', 'John');
   */
  set(key, value) {
    // Get old value for event
    const oldValue = this.get(key);

    // save in storage
    this.data[key] = value;
    this.persist();

    this.fire(this.getEventName(EVENTS.CHANGE, key), value, oldValue, EVENTS.CHANGE, key);

    return this;
  }

  /**
   * Read value from storage
   * @param {String} key
   * @returns {any}
   * @example
   * storage.get('name'); // John
   */
  get(key) {
    const value = this.data[key];

    // fire event
    this.fire(this.getEventName(EVENTS.READ, key), value);

    return value;
  }

  /**
   * Check if key exists in storage
   * @param {String} key
   * @returns {Boolean}
   * @example
   * storage.has('name'); // true
   */
  has(key) {
    return typeof this.data[key] !== 'undefined' && this.data[key] !== null;
  }

  /**
   * Clear all keys from storage
   *
   * @returns {Storage}
   */
  clear() {
    // Get old values and keys for event
    const keys = Object.keys(this.data);
    const oldValues = { ...this.data };

    // Clear data
    this.data = {};
    this.persist();

    // Fire remove event for each key
    keys.forEach((key) => {
      this.fire(
        this.getEventName(EVENTS.REMOVE, key),
        undefined,
        oldValues[key],
        EVENTS.REMOVE,
        key
      );
    });
    // fire clear event
    this.fire(EVENTS.STORAGE_CLEAR);

    return this;
  }

  /**
   * Remove a key from storage
   *
   * @param {...String} key keys to remove
   * @returns {Storage}
   */
  remove(...keys) {
    keys.forEach((key) => {
      // Get old value for event
      const oldValue = this.get(key);

      // Delete key from storage
      delete this.data[key];
      this.persist();

      // Fire remove event
      this.fire(this.getEventName(EVENTS.REMOVE, key), undefined, oldValue, EVENTS.REMOVE, key);
    });

    return this;
  }

  /**
   * Persist data to localStorage
   * @returns {void}
   */
  persist() {
    localStorage.setItem(DATA_KEY, JSON.stringify(this.data));
  }

  /**
   * Events
   */

  /**
   * @param {String} event event name
   * @param {String} key key to listen to
   * @param {Function} callback
   */
  on(event, key, callback) {
    this.events.on(this.getEventName(event, key), callback);
  }

  /**
   * @param {String} event event name
   * @param {String} key key to listen to
   * @param {Function} callback
   */
  once(event, key, callback) {
    this.events.once(this.getEventName(event, key), callback);
  }

  /**
   * @param {String} event event name
   * @param {String} key key to listen to
   * @param {Function} callback
   */
  one(event, key, callback) {
    this.events.one(this.getEventName(event, key), callback);
  }

  /**
   * @param {String} event event name
   * @param {String} key key to listen to
   * @param {...String} args
   * @param {Function} callback
   */
  fire(event, ...args) {
    this.events.emit(event, ...args);
  }
}

const storage = new Storage();

export default storage;
