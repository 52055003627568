import { Alert, Space, message, notification } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { onSocketConnected } from 'store/socket/socket';
import ReactDOM from 'react-dom';

const alertPositions = {
  top: 'page-alerts-container',
  dashboard: 'dashboard-alerts-container',
};

const Notifications = (props) => {
  const [alerts, setAlerts] = useState({});
  const [messages, messagesContextHolder] = message.useMessage();
  const [api, notificationsContextHolder] = notification.useNotification();

  const alertReceived = (alert) => {
    const props = {};

    if (alert.hide) {
      return closeAlert(alert.key);
    }

    props.key = alert.key;
    props.message = alert.title;
    props.description = alert.message;
    props.banner = alert.banner;
    props.type = alert.type;

    // if not closable, then remove close icon
    if (Boolean(alert.closable)) {
      props.closable = true;
    } else {
      props.closeIcon = null;
    }

    // if icon is specified, then icon is applied as <img /> tag
    props.icon = alert.icon ? <img src={alert.icon} className='w-4 h-4' /> : null;

    // If autoclose duration is specified
    const durationSpecified = parseFloat(Math.max(alert.duration, alert.max_duration));
    if (durationSpecified > 0) {
      setTimeout(() => closeAlert(alert.key), durationSpecified);
    }
    props.afterClose = () => closeAlert(alert.key);

    props.position = alertPositions[alert.position] ?? alertPositions.top;

    setAlerts((alerts) => {
      alerts[props.key] = props;
      return { ...alerts };
    });
  };

  const closeAlert = (key) => {
    setAlerts((alerts) => {
      delete alerts[key];
      return { ...alerts };
    });
  };

  const renderAlert = (alertProps) => {
    return <Alert key={alertProps.key} {...alertProps} />;
  };

  useEffect(() => {
    // map every possible alert position
    Object.values(alertPositions).forEach((id) => {
      // Get container to place
      const container = document.getElementById(id);
      if (!container) return;

      // filter alerts by position and create them
      const alertsToPlace = Object.values(alerts)
        .filter((a) => a.position === id)
        .map((a) => renderAlert(a));

      // Place created alerts in DOM
      ReactDOM.render(
        alertsToPlace.length ? (
          <div className='py-2'>
            <Space direction='vertical' style={{ display: 'flex' }}>
              {alertsToPlace}
            </Space>
          </div>
        ) : null,
        container
      );
    });
  }, [alerts]);

  const messageReceived = (data) => {
    const props = {
      key: data.key,
      className: 'top-[64px]',
      content: data.title,
    };

    message.info(props);
  };

  useEffect(() => {
    onSocketConnected('alertEvent', (socket) => {
      socket.on('global.alert', alertReceived);
    });
    onSocketConnected('messagesEvent', (socket) => {
      socket.on('global.message', messageReceived);
    });
  }, []);

  return (
    <Fragment>
      {messagesContextHolder}
      {notificationsContextHolder}
    </Fragment>
  );
};

export default Notifications;
