import { mdiWeb } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { Hidden } from '@material-ui/core';
import useAuth from 'store/redux/hooks/useAuth';
import { Modal, Checkbox } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import { useTour, useTutorial } from 'store/redux/states/ui/ui.hooks';
import { useRevenueSourceUpdateMutation } from 'store/redux/apis/user.api';

const RevenueSelect = ({ defaultValue, onChange }) => {
  return (
    <Select
      className='flex items-center justify-center'
      size='middle'
      popupMatchSelectWidth={false}
      defaultValue={defaultValue}
      onChange={onChange}
      options={[
        {
          label: (
            <div className='flex gap-2 items-center'>
              <Icon path={mdiWeb} className='text-orange' />
              Revenue Data From Ad Networks
            </div>
          ),
          value: 'network',
        },
        {
          label: (
            <div className='flex gap-2 items-center'>
              <img src='/icons/dataninja/dataninja.svg' className='w-[18px]' alt='Dataninja' />
              Revenue Data From Ninja CRM
            </div>
          ),
          value: 'ninja',
        },
      ]}
    />
  );
};

const Header = () => {
  const [revenueSourceUpdate] = useRevenueSourceUpdateMutation();
  const { user } = useAuth();
  const classes = useStyles();
  const [tutorial, setTutorialOpen] = useTutorial();
  const [tourId] = useTour();
  const [modal, setModal] = useState(tutorial === true && tourId === 'revenue' ? true : false);

  useEffect(() => {
    tourId === 'revenue' && tutorial === true ? setModal(true) : setModal(false);
  }, [tourId]);

  const handleResource = (value) => {
    revenueSourceUpdate({ source: value })
      .unwrap()
      .then(() => {
        window.location.reload();
      });
  };

  const modalStyle = {
    position: 'relative',
    zIndex: 999999,
  };

  const modalProps = {
    open: modal,
    footer: null,
    getContainer: false,
    style: modalStyle,
  };

  return (
    <div className={classes.revenue}>
      <Hidden smUp>
        <div className={classes.select} onClick={() => !setModal(!modal)}>
          <Checkbox defaultChecked size='small' checked={true} />
          {user.revenue_source === 'ninja' ? (
            <img src='/images/dataninja.svg' alt='Dataninja' className='w-7' />
          ) : (
            <Icon path={mdiWeb} className='text-orange !w-7 !h-7' />
          )}
        </div>
        <Modal {...modalProps} onCancel={() => setModal(false)}>
          <div className={classes.revenueMd} id='revenue'>
            <RevenueSelect defaultValue={user.revenue_source} onChange={handleResource} />
          </div>
        </Modal>
      </Hidden>
      <Hidden smDown>
        <div id='revenue' className={classes.select}>
          <RevenueSelect defaultValue={user.revenue_source} onChange={handleResource} />
        </div>
      </Hidden>
    </div>
  );
};

export default Header;
const useStyles = makeStyles((theme) => ({
  select: {
    cursor: 'pointer',
    borderRight: `2px solid ${theme.colors.bgColorLight}`,
    height: '64px',
    padding: '8px 24px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  revenueMd: {
    margin: '30px 0px 10px',
    '& .ant-select-selector': {
      height: '50px !important',
    },
    '& .ant-select-selection-item': {
      display: 'flex !important',
    },
  },
  revenue: {
    '& .ant-modal-root': { position: 'relative', zIndex: 999999999999999 },
  },
}));
