import React from 'react';
import ConditionsFields from './components/ConditionsFields';
import { Button } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';

const FilterQueryBuilder = ({ error, data: _data = [[]], fields, onChange }) => {
  let data = cloneDeep(_data);

  // Ensure each condition object has a valid id
  data.forEach((orConditions) => {
    orConditions.forEach((condition) => {
      if (!condition.id) {
        condition.id = uuidv4();
      }
    });
  });

  const handleDeleteCondition = (orConditions, i, j) => {
    if (data.length === 1 && orConditions.length === 1) {
      onChange([[{}]]);
      return;
    }
    orConditions.splice(j, 1);
    if (!orConditions.length && data.length > 1) {
      data.splice(i, 1);
    }
    onChange(data);
  };

  const handleDataCondition = (orConditions, i) => {
    if (data.length === 1) {
      onChange([[{}]]);
      return;
    }
    data.splice(i, 1);
    onChange(data);
  };

  return (
    <div className='rounded-md w-auto grid gap-1.5'>
      {data.map((orConditions, i) => (
        <div key={i} className='relative'>
          <div
            className='flex rounded-md p-4 h-full bg-background-light'
            style={{ border: `1px solid ${error ? '#FF0000' : '#dbdee0'}` }}
          >
            <div className='h-full flex flex-col'>
              <span className='bg-color-xlight text-color-medium font-normal text-xs px-2 py-1 rounded-md'>
                and
              </span>

              <div className='w-full h-full'>
                <span
                  style={{
                    marginLeft: 'auto',
                  }}
                  className='flex rounded-md bg-color-xlight h-full w-px'
                />
              </div>
            </div>
            <div className='grid gap-2 w-full'>
              <div className='flex gap-2 justify-end'>
                <Button
                  size='small'
                  icon={<PlusOutlined className='w-3 h-3' />}
                  className='w-fit flex items-center'
                  onClick={() => {
                    data[i].push({ id: uuidv4() });
                    onChange(data);
                  }}
                >
                  <span className='!text-xs'>Add Rule</span>
                </Button>
                <Button
                  size='small'
                  icon={<DeleteOutlined />}
                  onClick={() => handleDataCondition(orConditions, i)}
                />
              </div>
              {fields &&
                orConditions.map((oc, j) => {
                  return (
                    <div key={oc.id} className='flex gap-2 items-center justify-between'>
                      <ConditionsFields
                        fields={fields?.fields}
                        data={oc}
                        onChange={(newOc) => {
                          orConditions[j] = newOc;
                          onChange(data);
                        }}
                      />
                      <DeleteOutlined
                        className='cursor-pointer'
                        onClick={() => handleDeleteCondition(orConditions, i, j)}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className='m-auto left-0 right-0 w-12 absolute z-10' style={{ bottom: '-16px' }}>
            <Button
              onClick={() => {
                data.push([{ id: uuidv4() }]);
                onChange(data);
              }}
              className='bg-white w-full !text-xs rounded-3xl flex items-center justify-center'
              size='small'
            >
              OR
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FilterQueryBuilder;
