import React, { useState, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar as SingleDate } from 'react-date-range';
import { Popover } from 'antd';
import TextField from '../TextField';
import { makeStyles, InputAdornment } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiCalendarMonth } from '@mdi/js';

const DatePicker = (props) => {
  const { formatter = 'yyyy-MM-dd', placeholder = 'Date', onChange, value } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(null);

  useEffect(() => {
    setDate(new Date(value));
  }, [value]);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const onChangeDate = (item) => {
    const date = Date.format(item, formatter);
    setDate(item);
    setOpen(false);

    onChange?.(date);
  };

  return (
    <Popover
      className={classes.calendar}
      placement='bottomLeft'
      open={open}
      onOpenChange={handleOpenChange}
      trigger='click'
      content={<SingleDate date={date} onChange={onChangeDate} />}
    >
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Icon className='text-orange' path={mdiCalendarMonth} />
            </InputAdornment>
          ),
        }}
        variant='outlined'
        fullWidth={true}
        value={value ? Date.format(date, formatter) : ''}
        placeholder={placeholder}
      />
    </Popover>
  );
};

export default DatePicker;

const useStyles = makeStyles(() => ({
  calendar: {
    '& .MuiInputBase-input::placeholder': {
      fontSize: 12,
      color: 'rgba(21, 26, 34, 0.60)',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ff4e00 !important',
      borderWidth: '1px !important',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ff4e00 !important',
      borderWidth: '1px !important',
    },
  },
}));
