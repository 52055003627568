import { Modal, Switch, Typography } from 'antd';
import PlanCard from 'components/PlanCard';
import { Page } from 'components/layouts/components';
import React, { useState } from 'react';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import { useListBillingPlansQuery } from 'store/redux/states/billing/billing.api';
import useBilling from 'store/redux/states/billing/hooks/useBilling';

const BillingIndexPage = (props) => {
  const { ...rest } = props;
  const { data: plans = [] } = useListBillingPlansQuery();
  const [yearly, setYearly] = useState(false);
  const { hasPaidPlan } = useWorkspace();
  const { billing, isLoading: isBillingDataLoading } = useBilling();

  const [closable, setClosable] = useState(false);

  const updateEnabled = hasPaidPlan();

  return (
    <Page>
      <div className='flex-col'>
        <div className='flex flex-col justify-center items-center gap-2 w-full py-4 '>
          {billing?.can_have_free_trial ? (
            <span className='text-2xl text-orange font-bold'>
              All Plans Come With 7 Days Free Trial
            </span>
          ) : null}

          <span className='text-lg text-center font-medium'>
            As we are in Beta Mode, we offer extra 1 Months{' '}
            <span className='text-lg font-bold underline'>99% OFF</span> to Unlimited Package.
            <br />
            Please, use <span className='text-lg font-bold underline'>BETANINJA99</span> coupon code
            upon checkout.
          </span>
        </div>
        <div className='flex justify-center items-center gap-2 w-full py-4 '>
          <Switch checked={yearly} onChange={(checked) => setYearly(checked)} />
          <Typography.Text strong>Pay Yearly</Typography.Text>
        </div>
        <div className='flex flex-center flex-wrap gap-4'>
          {plans.map((plan) => {
            return (
              <div className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4'>
                <PlanCard
                  onCheckout={() => {
                    setClosable(true);
                  }}
                  isCurrent={plan.code === billing?.plan?.code}
                  plan={plan}
                  yearly={yearly}
                  free_trial_active={billing?.can_have_free_trial}
                  update={updateEnabled}
                  button_text={billing?.can_have_free_trial ? 'Try For Free' : 'Choose'}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Page>
  );
};

export default BillingIndexPage;
